import { Meta, Tag } from '@navi-app/ui';
import {
  formatDateToLocal,
  useWindowSize,
  withErrorBoundary,
} from '@navi-app/utils';
import { Spinner } from 'baseui/spinner';
import { DisplaySmall, HeadingSmall, LabelSmall } from 'baseui/typography';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import useCheckArticle from '../../commons/hooks/use-check-article';
import useArticleDetail from './hooks/use-article-detail';
import './styles.scss';

const FooterLanding = lazy(
  () => import('../../commons/components/footer-landing')
);
const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);
const SectionMoreArticle = lazy(
  () => import('./components/section-more-article')
);

function ArticleDetailPage() {
  const { t } = useTranslation(['common', 'article']);
  const { isHasArticle } = useCheckArticle();
  const {
    detailArticle,
    isLoadingDetailArticle,
    isLoadingListArticle,
    listArticle,
  } = useArticleDetail();
  const { isPhoneSize } = useWindowSize();

  if (isLoadingDetailArticle || isLoadingListArticle) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner $size="60" $borderWidth="6" />
      </div>
    );
  }
  return (
    <>
      <Meta
        key="meta-article-detail"
        title={`${detailArticle?.title} | by Mobitech | ${
          formatDateToLocal(detailArticle?.publishedDate, 'dd MMM yyyy') || '-'
        } | Mobitech Id`}
        description={`${t('article:meta.description')}: ${
          detailArticle?.title
        }`}
        keywords="pengiriman, order, aplikasi logistik"
        openGraph={{
          title: `${t('article:meta.title')}: ${detailArticle?.title}`,
          description: `${t('article:meta.description')}: ${
            detailArticle?.title
          }`,
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          type: 'website',
          url: window?.location?.href || config.BRAND_URL,
        }}
        twitter={{
          card: 'summary_large_image',
          title: `${t('article:meta.title')}: ${detailArticle?.title}`,
          description: `${t('article:meta.description')}: ${
            detailArticle?.title
          }`,
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          url: window?.location?.href || config.BRAND_URL,
        }}
        article={{
          tag: detailArticle?.category,
          publishedTime: detailArticle?.publishedDate,
          modifiedTime:
            detailArticle?.updatedAt || detailArticle?.publishedDate,
        }}
        canonicalLink={window?.location?.href || config.BRAND_URL}
        structuredData={
          detailArticle && {
            '@context': 'https://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': window?.location?.href,
            },
            headline: detailArticle?.title,
            description: detailArticle?.summaryText,
            image:
              detailArticle?.headerImage ||
              'https://assets.mobitech.id/images/mobitech-logo.webp',
            author: {
              '@type': 'Person',
              name: 'Mobitech ID',
            },
            publisher: {
              '@type': 'Organization',
              name: 'Mobitech ID',
              logo: {
                '@type': 'ImageObject',
                url: 'https://assets.mobitech.id/images/mobitech-logo.webp',
              },
            },
            datePublished: detailArticle?.publishedDate,
            dateModified:
              detailArticle?.updatedAt || detailArticle?.publishedDate,
          }
        }
        config={config}
      />
      <Suspense>
        <HeaderLanding
          config={layoutConfig}
          isHasArticle={isHasArticle}
          isLoadingArticle={isLoadingListArticle}
        />
        <main className="lg:px-[120px] lg:py-[60px] p-4">
          <div className="flex items-center gap-3 mb-4">
            <Tag
              content={<h4>{detailArticle?.category}</h4>}
              kind="secondary"
            />
            <LabelSmall color="#A6A6A6" as="h5">
              {formatDateToLocal(
                detailArticle?.publishedDate || new Date().toISOString(),
                'dd MMM yyyy'
              ) || '-'}
            </LabelSmall>
          </div>
          {isPhoneSize ? (
            <HeadingSmall>{detailArticle?.title}</HeadingSmall>
          ) : (
            <DisplaySmall as="h1">{detailArticle?.title}</DisplaySmall>
          )}
          <img
            src={detailArticle?.headerImage}
            alt={`mobitech: aplikasilogistik: article: ${detailArticle?.title}`}
            className="bg-gray-100 rounded-2xl xs:h-auto lg:h-[680px] w-full object-cover mt-8"
          />
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 mt-12 mb-7">
            <div
              dangerouslySetInnerHTML={{
                __html: detailArticle?.content,
              }}
              className="lg:border-r lg:border-[#DDDDDD] lg:pr-10 text-justify lg:col-span-3 editor-container"
            />
            {isLoadingListArticle ? (
              <Spinner />
            ) : (
              <Suspense>
                <SectionMoreArticle
                  t={t}
                  articles={listArticle}
                  isPhoneSize={isPhoneSize}
                />
              </Suspense>
            )}
          </div>
        </main>
        <FooterLanding />
      </Suspense>
    </>
  );
}

export default withErrorBoundary(ArticleDetailPage);
