import { ConfigurationOverride } from 'baseui/helpers/overrides';
import {
  ACCESSIBILITY_TYPE,
  StatefulContentRenderProp,
  StatefulTooltip,
} from 'baseui/tooltip';
import { ReactNode } from 'react';

interface TextEllipsisProps {
  line: number;
  propsTooltip?: {
    content?: StatefulContentRenderProp | ReactNode | string;
    placement?:
      | 'auto'
      | 'topLeft'
      | 'top'
      | 'topRight'
      | 'rightTop'
      | 'right'
      | 'rightBottom'
      | 'bottomRight'
      | 'bottom'
      | 'bottomLeft'
      | 'leftBottom'
      | 'left'
      | 'leftTop'
      | undefined;
    triggerType?: 'click' | 'hover' | undefined;
    width?: number | string;
    minLengthText?: number;
  };
  children: ReactNode;
  overrideBodyStyle?:
    | { [key: string]: string | number }
    | ConfigurationOverride;
  additionalClass?: string;
}

const TextEllipsis: React.FC<TextEllipsisProps> = ({
  line,
  propsTooltip,
  children,
  overrideBodyStyle,
  additionalClass,
}) => {
  const style: React.CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: line,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    whiteSpace: 'no-wrap',
  };

  const {
    content,
    placement = 'bottom',
    triggerType = 'hover',
    width = '300px',
    minLengthText = 30,
  } = propsTooltip || {};

  const showTooltip = () => {
    if (typeof content === 'string' && content.length > minLengthText)
      return true;
    else if (typeof content !== 'string') return true;
    return false;
  };

  return showTooltip() ? (
    <StatefulTooltip
      content={content}
      placement={placement}
      triggerType={triggerType}
      showArrow
      returnFocus
      autoFocus
      ignoreBoundary
      accessibilityType={ACCESSIBILITY_TYPE.tooltip}
      overrides={{
        Body: {
          style: {
            maxWidth: typeof width === 'string' ? width : `${width}px`,
            zIndex: '99',
            ...overrideBodyStyle,
          },
        },
      }}
    >
      <div style={style} className={additionalClass}>
        {children}
      </div>
    </StatefulTooltip>
  ) : (
    <div style={style} className={additionalClass}>
      {children}
    </div>
  );
};

export default TextEllipsis;
